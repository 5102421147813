<template>
  <div>
    <KTCodePreview v-bind:title="'In phiếu cho mượn máy'">
      <template v-slot:toolbar>
        <b-form-group
          label="Đặt khổ in mặc định"
          v-slot="{ ariaDescribedby }"
          style="font-size: small; color: black"
        >
          <b-form-radio-group
            v-model="printMode"
            :options="printModeOptions"
            :aria-describedby="ariaDescribedby"
            name="plain-inline"
            plain
          ></b-form-radio-group>
        </b-form-group>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="onClickToPrint(printMode)"
        >
          <i
            style="font-size: 1rem"
            class="fas fa-print"
          ></i>In phiếu
        </b-button>
      </template>
      <template v-slot:preview>
        <hr
          class="hr-text"
          data-content="Khổ giấy A4- A5"
          style="font-weight: 600"
        />
        <div id="A4">
          <PrintLendingA4 :printModel="printModel" />
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import PrintLendingA4 from '../../components/stock-slips/PrintLendingA4';
// import { TIME_TRIGGER } from '@/utils/constants';
import saveData from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
    PrintLendingA4,
  },
  data() {
    return {
      renderComponent: false,
      printMode: 'A4',
      printModeOptions: [{ text: 'Khổ A4- A5', value: 'A4' }],
      printModel: {
        products: [],
        date: null,
        month: null,
        year: null,
        storeName: '',
        storeAddress: '',
      },
    };
  },
  created() {
    this.stockSlipId = this.$route.query.id;
    if (this.stockSlipId) {
      this.getStockById();
    }
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu cho mượn máy', route: '/lending-stock' },
      { title: 'In phiếu cho mượn máy' },
    ]);
  },
  methods: {
    getStockById: function () {
      this.renderComponent = false;
      ApiService.get(`warranty-stock/${this.stockSlipId}`).then((response) => {
        const { data } = response.data;
        this.printModel.products = [];
        this.printModel.storeName = data.storeName;
        this.printModel.storeAddress = data.storeAddress;
        this.printModel.products = data.listDetail.map((element) => {
          return {
            id: element.id,
            name: element.productName,
            IMEI: element.productImei,
            proId: element.productId,
            price: element.unitPrice,
            quantity: element.quantity,
            totalPrice: element.totalAmount,
            productType: element.productType,
            formatImei: element.productImei,
            totalQuantityInStock: element.totalQuantityInStock || 0,
            discount: element.discount || 0,
            barCode: element.barCode || '',
            productCode: element.productCode || '',
          };
        });
        const dateObj = new Date();
        this.printModel.date = dateObj.getUTCDate();
        this.printModel.month = dateObj.getUTCMonth();
        this.printModel.year = dateObj.getUTCFullYear();
        this.renderComponent = true;
      });
    },
    printData(mode, stylePrint) {
      const prtHtml = document.getElementById(mode).innerHTML;

      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            ${stylesHtml}
            ${stylePrint}
        </head>
        <body>`);
      WinPrint.document.write(`${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, 500);
    },
    getStylePrint() {
      return `<style>
                .font-size-print {font-size: 20px !important; color: black;}
                .font-size-gift {font-size: 18px !important; color: black;}
                .font-size-name{ font-size: 20px !important; color: black;}
                .font-size-warranty{ font-size: 26px !important; color: black;}
                .font-size-product-info{ font-size: 20px !important; color: black;}
                .font-size-price{ font-size: 30px !important; font-weight: bold !important;}
                .text-download-app {font-size: 14px !important; font-weight: 550 !important; margin-top: 4px;}
                .print-lending {
                    width: 100% !important;
                    margin-left: 0px !important;
                  }
              </style>`;
    },
    onClickToPrint(mode) {
      this.printMode = mode;
      const stylePrint = this.getStylePrint();
      this.printData(mode, stylePrint);
    },
    onChangeDefaultMode() {
      saveData.saveData('defaultPrintMode', this.printMode);
    },
  },
};
</script>
